import http from "../http/httpService";

class TokenAuthService {
  async authenticate(authenticationInput) {
    let result = await http.post(
      "api/TokenAuth/Authenticate",
      authenticationInput
    );
    return result.data.result;
  }

  async externalAuthenticate(authenticationInput) {
    let result = await http.post(
      "api/TokenAuth/ExternalAuthenticate",
      authenticationInput
    );
    return result.data.result;
  }
}

export default new TokenAuthService();

import { useQueries, useQuery, useQueryClient } from 'react-query';
import filesService, { getFileName } from '../../../../services/files/filesService';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FileUploader from '../../../../components/fileUpload/FileUploader';
import download from 'downloadjs';
import { useNotifications } from '../../../../hooks/notifications/notificationsHook';
import { RenderVerseChapterName } from './BibleDialog';
import { isGranted } from '../../../../utils/abpUtility';

export const MultimediaActivity = ({ meeting }) => {
    const [fileToUpload, setFileToUpload] = useState(null);
    const [originalFile, setOriginalFile] = useState(null);
    const [uploadLoading, setUploadLoading] = useState();
    const [uploadFileMode, setUploadFileMode] = useState(false);
    const { addFile, removeFile } = useNotifications();
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const queryClient = useQueryClient();

    const { data: files } = useQuery({
        queryKey: ['files', meeting.id],
        queryFn: () => filesService.getAll({ Keyword: meeting.id })
    });

    const { data: verses } = useQuery({
        queryKey: ['verses_download', meeting.id],
        queryFn: () => filesService.getVerses({ meetingId: meeting.id }),
        enabled: !!meeting.id
    });

    const fileIds = useMemo(() => {
        const fieldsItems = files?.items || [];
        return fieldsItems.map((i) => i.fileId);
    }, [files]);

    const results = useQueries(
        fileIds.map((i) => {
            return { queryKey: ['thumbnails', i], queryFn: () => filesService.getPCloudThumbnail({ fileId: i }) };
        })
    );

    const thumbnails = useMemo(() => {
        return results.map((i) => i.data || {}) || [];
    }, [results]);

    const onFileSelect = (e, orgFile) => {
        setFileToUpload(e);
        setOriginalFile(orgFile);
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (uploadInProgress) {
                const confirmationMessage = 'Are you sure you want to leave? Your upload is still in progress.';
                event.returnValue = confirmationMessage; // For older browsers
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [uploadInProgress]);

    const onUpload = async () => {
        const orgFile = originalFile.files?.[0];
        const file = {
            fileName: orgFile.name,
            image: orgFile.objectURL,
            action: 'upload'
        };

        addFile(file);

        setUploadInProgress(true);
        setUploadLoading(true);
        setUploadFileMode(false);

        try {
            const response = await filesService.pCloudUploadMeetingFile(fileToUpload, meeting.id, orgFile.name);
            if (!response) return;

            await queryClient.refetchQueries('files');
        } catch (error) {
            console.error('Upload failed:', error);
        } finally {
            setUploadInProgress(false);
            setUploadLoading(false);
            removeFile(file);
        }
    };

    const downloadVerse = async (verse) => {
        let fileBase64 = `data:${verse.contentType};base64,${verse.content}`;
        download(fileBase64, verse.fileName, verse.contentType);
    };

    return (
        isGranted('Pages.Media.All') && (
            <ThumbnailsContainer>
                <ThumbnailsHeader>
                    <h3>Media:</h3>
                    {isGranted('Pages.Media.Upload') && !uploadFileMode && <Button label="Upload File" disabled={uploadLoading} onClick={() => setUploadFileMode(true)} />}
                    {uploadFileMode && <Button label="Close" severity="danger" disabled={uploadLoading} onClick={() => setUploadFileMode(false)} />}
                </ThumbnailsHeader>
                {uploadFileMode && <FileUploader useCrop={true} multiple={false} onSelect={onFileSelect} />}
                {fileToUpload && <Button disabled={uploadLoading} loading={uploadLoading} label="Upload file" onClick={onUpload} />}
                <div className="flex justify-content-between">
                    <div>
                        {thumbnails.map((file, idx) => (
                            <ThumbnailItem file={file} key={idx} />
                        ))}
                    </div>
                    <div>
                        {verses?.map((verse) => (
                            <RenderVerseDownload verse={verse} key={verse.id} onDownload={() => downloadVerse(verse)} />
                        ))}
                    </div>
                </div>
            </ThumbnailsContainer>
        )
    );
};

const VerseItem = styled.div`
    width: 300px;
    background-color: #f5f5f7;
    padding: 10px 15px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

const RenderVerseDownload = ({ verse, onDownload }) => {
    const verse_text = useMemo(() => {
        const verse_names = verse?.fileName?.replace('.txt', '') || '';
        if (!verse_names) return [];

        const verses = verse_names.split('_');
        return verses;
    }, [verse]);
    return (
        <VerseItem>
            <ul>
                {verse_text.map((v) => (
                    <li key={v}>
                        <RenderVerseChapterName text={v} />
                    </li>
                ))}
            </ul>
            <Button rounded icon="fa-solid fa-download" onClick={onDownload} />
        </VerseItem>
    );
};

const ThumbnailItem = ({ file }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [cloudUrl, setCloudUrl] = useState(null);
    const { addFile, removeFile } = useNotifications();

    const downloadRef = useRef(null);

    const queryClient = useQueryClient();

    const handleDownload = async () => {
        setLoading(true);
        file.action = 'download';
        addFile(file);
        const fileToDownload = await filesService.receivePCloudFile({
            fileId: file.fileId
        });
        if (!fileToDownload) return;

        let fileBase64 = `data:${fileToDownload.contentType};base64,${fileToDownload.content}`;

        const res = download(fileBase64, getFileName(fileToDownload.fileName), fileToDownload.contentType);
        if (res) {
            removeFile(file);
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);

        await filesService.pCloudDeleteFile(file.fileId);
        await queryClient.refetchQueries('files');
        setLoading(false);
    };

    return (
        <Thumbnail key={file.requestUri}>
            <Dialog
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    setLoading(false);
                }}
                style={{ width: '60vw', position: 'relative', height: '600px' }}
            >
                <IFrameContainer ref={downloadRef} src={cloudUrl} title={cloudUrl} width={'100%'} height={500} />
            </Dialog>
            <ThumbnailImage src={file?.image} alt="" />
            <ThumbnailActions>
                {isGranted('Pages.Media.Download') && <Button rounded icon="fa-solid fa-download" onClick={() => handleDownload()} disabled={loading} loading={loading} />}
                {isGranted('Pages.Media.Delete') && <Button rounded icon="fa-solid fa-trash" severity="danger" onClick={() => handleDelete()} disabled={loading} loading={loading} />}
            </ThumbnailActions>
        </Thumbnail>
    );
};

const IFrameContainer = styled.iframe`
    border: none;
`;

const ThumbnailsHeader = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    h3 {
        margin: 0px;
    }
`;

const ThumbnailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ThumbnailActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
`;

const Thumbnail = styled.div`
    width: 300px;
    background-color: #f5f5f7;
    padding: 10px 15px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

const ThumbnailImage = styled.img`
    max-width: 150px;
    max-height: 100px;
    object-fit: contain;
    border-radius: 10px;
`;

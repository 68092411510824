import AppConsts from '../http/appconst';
import http from '../http/httpService';
import tokenAuthService from '../tokenAuth/tokenAuthService';

let abp = window.abp;

class AuthenticationService {
    isAuthenticated() {
        return abp.session.userId ? true : false;
    }

    async getCurrentLoginInfo() {
        let result = await http.get('api/services/app/Session/GetCurrentLoginInformations');
        return result.data.result;
    }

    async login(model) {
        let result = await tokenAuthService.authenticate({
            userNameOrEmailAddress: model.userNameOrEmailAddress,
            password: model.password,
            rememberClient: model.rememberMe
        });

        var tokenExpireDate = model.rememberMe ? new Date(new Date().getTime() + 1000 * result.expireInSeconds) : undefined;
        abp.auth.setToken(result.accessToken, tokenExpireDate);
        abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, tokenExpireDate, abp.appPath);
        if (!result.accessToken) {
            throw Error('error');
        }
        return result;
    }

    async getExternalAuthenticationProviders() {
        let result = await http.get('api/TokenAuth/GetExternalAuthenticationProviders');
        return result.data.result;
    }

    async externalAuthentiate(model) {
        let result = await tokenAuthService.externalAuthenticate(model);
        let tokenExpireDate = model.rememberMe ? new Date(new Date().getTime() + 1000 * result.expireInSeconds) : undefined;
        abp.auth.setToken(result.accessToken, tokenExpireDate);
        abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, result.encryptedAccessToken, tokenExpireDate, abp.appPath);
        if (!result.accessToken) {
            throw Error('error');
        }

        return result;
    }

    logout() {
        localStorage.clear();
        sessionStorage.clear();
        abp.auth.clearToken();
    }
}
export default new AuthenticationService();

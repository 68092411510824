import { useQuery } from 'react-query';
import meetingsService from '../../services/meetings/meetingsService';

export const useMeetings = () => {
    const { data } = useQuery({
        queryKey: ['meetings'],
        queryFn: meetingsService.getAll
    });

    let meetings = data?.items || [];
    return { meetings };
};

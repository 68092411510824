import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import meetingsService from '../../../services/meetings/meetingsService';
import { useMeetings } from '../../../hooks/meetings/meetingsHook';
import { Button, Dropdown, ProgressSpinner } from 'primereact';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { MeetingsContext } from '../../../components/configuration/InitialConfigurationWrapper';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { SermonActivity } from './activities/SermonActivity';
import { MultimediaActivity } from './activities/MultimediaActivity';
import { isGranted } from '../../../utils/abpUtility';

const getTimeFormatted = (date, time) => {
    let dateObj = new Date(date);

    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    if (time) {
        options.hour = '2-digit';
        options.minute = '2-digit';
    }
    let timeString = dateObj.toLocaleDateString('bg-BG', options);
    return timeString;
};

export const MeetingDetails = () => {
    const { latestMeeting } = useContext(MeetingsContext);
    const { meetingId } = useParams();
    const { meetings } = useMeetings();
    const query = useQueryClient();

    let meetingOptions = useMemo(() => {
        return meetings.map((meeting) => {
            return {
                id: meeting.id,
                label: `${meeting.name} - ${getTimeFormatted(meeting.startTime)}`
            };
        });
    }, [meetings]);

    let navigate = useNavigate();

    const { data, isLoading } = useQuery({
        queryKey: ['meeting', meetingId],
        queryFn: () => meetingsService.get({ id: meetingId }),
        enabled: !!meetingId,
        retry: false
    });

    const onValueChange = (e) => {
        let id = e.value;

        navigate(`/meetings/${id}/details`);
    };

    const accept = async () => {
        await meetingsService.delete({ id: meetingId }).catch((e) => {});
        await query.refetchQueries(['latestMeeting']);
        await query.refetchQueries(['meetings']);

        navigate(`/meetings/${latestMeeting?.id}/details`);
    };

    const deleteBtnClicked = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this meeting?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject: () => {}
        });
    };
    return (
        <div className="card flex flex-column gap-5">
            <ConfirmPopup />
            <div className="flex justify-content-between">
                {isGranted('Pages.Meeting') && <Dropdown placeholder="Select a meeting" options={meetingOptions} optionLabel="label" optionValue="id" value={meetingId} onChange={onValueChange} style={{ width: 'fit-content' }} />}
                {isGranted('Pages.Meeting.Delete') && <Button severity="danger" rounded icon="pi pi-trash" onClick={deleteBtnClicked} />}
            </div>
            {data && <Meeting meeting={data} />}
            {isLoading && <ProgressSpinner style={{ alignSelf: 'center' }} />}
            {!data && !isLoading && (
                <NoMeetingFound>
                    <h1>Meeting not found :)</h1>
                </NoMeetingFound>
            )}
        </div>
    );
};

const NoMeetingFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RenderActivity = ({ activity, meeting }) => {
    switch (activity) {
        case 'Sermon':
            return <SermonActivity meeting={meeting} />;
        case 'Multimedia':
            return <MultimediaActivity meeting={meeting} />;
        default:
            return activity;
    }
};

const Meeting = ({ meeting }) => {
    return (
        <div className="flex flex-column gap-5">
            <div className="flex gap-4">
                <h4 className="m-0">Тема: {meeting.name}</h4>
                <h4 className="m-0">Дата: {getTimeFormatted(meeting.startTime, true)}</h4>
            </div>
            {meeting?.meetingActivities?.map((activity) => (
                <ActivityArea key={activity.activity}>
                    <RenderActivity activity={activity.activity} meeting={meeting} />
                </ActivityArea>
            ))}
        </div>
    );
};

const ActivityArea = styled.div`
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    padding: 30px;
`;
